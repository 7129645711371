<template>
  <div class="my-upload-wrapper">
    <div class="my-img-wrapper" v-for="(file, index) in fileList" :key="index">
      <img class="my-img" @click="handleShowImage(index)" :class="imgClass" :src="file.url" alt="">
      <!-- <div class="upload-mask" v-if="file.success">
        <van-icon class="success" name="checked" />
      </div> -->
      <div class="upload-mask" v-if="file.progress <= 100 && !file.success">
        <van-circle
          class="upload-progress"
          v-model="file.currentProgress"
          :rate="file.progress"
          :text="file.currentProgress + '%'"
          :speed="progressSpeed"
          :size="progressSize"
          :color="progressColor"
          :fill="progressFill"
          :layer-color="progressLayerColor"
          :stroke-width="progressStrokeWidth"
          :clockwise="progressClockwise"
        />
      </div>
      <van-icon @click="deleteFile(index)" class="close-btn" name="clear" />
    </div>
    <van-uploader
      v-if="isOverFileCount"
      :capture="capture"
      :accept="accept"
      :disabled="disabled"
      :max-size="maxSize"
      :max-count="maxCount"
      :upload-text="uploadText"
      :after-read="afterRead"
      :preview-image="true"
      :before-read="beforeRead"
      @oversize="oversize">
      <slot></slot>
    </van-uploader>
  </div>
</template>

<script>
import axios from 'axios'
import { FILE_UPLOAD } from '@/configs/config'
import EXIF from '@/utils/smallExif'
import { mapState } from 'vuex'
export default {
  name: 'MyUpload',
  props: {
    // 上传组件Props开始
    files: { // 已上传的文件列表
      type: Array,
      default () {
        return []
      }
    },
    imgClass: { // 上传后的图片样式
      type: String,
      default: ''
    },
    accept: { // 接受的文件类型
      type: String,
      default: 'image/*'
    },
    capture: { // 图片选取模式，可选值为camera(直接调起摄像头)
      type: String,
      default: ''
    },
    disabled: { // 是否禁用文件上传
      type: Boolean,
      default: false
    },
    beforeRead: { // 文件读取前的回调函数，返回false可终止文件读取，支持返回Promise
      type: Function,
      default () {
        return () => {}
      }
    },
    maxSize: { // 文件大小限制，单位为byte
      type: Number
    },
    maxCount: { // 文件上传数量限制
      type: Number
    },
    uploadText: { // 上传区域文字提示
      type: String
    },
    fileName: { // 上传文件时文件的参数名
      type: String,
      default: 'file'
    },
    data: { // 上传需要附加数据
      type: Object,
      default () {
        return {}
      }
    },
    timeout: {
      type: Number,
      default: 60000
    },
    // 压缩质量
    compressQuality: {
      type: Number,
      default: 0.8
    },
    // 上传组件Props结束

    // 进度条组件Props开始
    progressSpeed: { // 动画速度（单位为 rate/s）
      type: Number,
      default: 100
    },
    progressSize: { // 圆环直径，默认单位为 px
      type: [Number, String],
      default: 100
    },
    progressColor: { // 进度条颜色，传入对象格式可以定义渐变色
      type: [String, Object],
      default: '#1989fa'
    },
    progressLayerColor: { // 轨道颜色
      type: String,
      default: '#fff'
    },
    progressFill: { // 填充颜色
      type: String
    },
    progressStrokeWidth: { // 进度条宽度
      type: Number,
      default: 40
    },
    progressClockwise: { // 是否顺时针增加
      type: Boolean,
      default: true
    }
    // 进度条组件Props结束
  },
  data () {
    return {
      fileList: this.files.slice()
    }
  },
  watch: {
    files (val) {
      this.fileList = val.slice()
    }

  },
  computed: {
    ...mapState(['token']),
    // 是否超过最大数量
    isOverFileCount () {
      if (this.maxCount === undefined) {
        return true
      } else {
        return this.maxCount > this.files.length
      }
    }
  },
  methods: {
    // 文件添加后
    // eslint-disable-next-line no-unused-vars
    afterRead ({ file, content }) {
      let id = new Date().getTime()
      let source = axios.CancelToken.source()

      file = this.compressImage(file, async file => {
        this.fileList.push({
          file,
          url: content,
          id,
          source,
          progress: 0,
          currentProgress: 0
        })

        let data = new FormData()
        // formData.append第三个参数filename是有浏览器兼容性问题的，如果不传就是filename=blob，后端校验文件名可能过不去
        data.append(this.fileName, file, file.name)
        for (let key in this.data) {
          data.append(key, this.data[key])
        }

        try {
          let res = await axios({
            method: 'post',
            url: FILE_UPLOAD,
            data,
            timeout: this.timeout,
            headers: {
              'Content-Type': 'multipart/form-data',
              'accessToken': this.token
            },
            cancelToken: source.token, // 这里声明的cancelToken其实相当于是一个标记
            onUploadProgress: progressEvent => {
              if (progressEvent.lengthComputable) {
              // 属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
              // 如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
                let index = this.getFileIndex(id)
                this.$set(this.fileList[index], 'progress', parseInt(progressEvent.loaded / progressEvent.total * 100))
              }
            }
          })

          if (res.data.success) {
            let index = this.getFileIndex(id)

            this.$set(this.fileList[index], 'success', true)
            this.$emit('uploadSuccess', res.data.data)
          } else {
            this.$toast.fail('上传出错了')
          }
        } catch (e) {
          console.log(e)

          if (axios.isCancel(e)) {
            this.$toast('取消上传')
          } else if (e.code === 'ECONNABORTED' && e.message.indexOf('timeout') !== -1) {
            this.$toast('请求超时')
            let index = this.getFileIndex(id)
            this.fileList.splice(index, 1)
          } else {
            let index = this.getFileIndex(id)
            this.fileList.splice(index, 1)
            // this.$toast.fail('服务器出错了')
            this.$toast.fail('网络波动，请重新上传！')
          }
        }
      })
    },
    // 删除文件
    deleteFile (index) {
      let source = this.fileList[index].source
      source && source.cancel('取消上传')
      // 删除 上传成功或者是已上传的文件时
      if (this.fileList[index].success || !source) {
        this.$emit('deleteFile', index, this.fileList[index])
      }
      this.fileList.splice(index, 1)
    },
    // 获取文件下标
    getFileIndex (id) {
      return this.fileList.findIndex(file => file.id === id)
    },
    // 图片大小超出时
    oversize (file) {
      this.$emit('oversize', file)
    },
    // 预览图片
    handleShowImage (index) {
      this.$emit('showImagePreview', index)
    },
    // 检测是否全部上传完成
    checkAllUploaded () {
      let noUpload = this.fileList.find(item => !item.success)
      return !noUpload
    },
    // 清除上传的文件
    clearFileList () {
      this.fileList = []
    },
    // 压缩图片
    compressImage (file, success) {
    // 图片小于1M不压缩
      if (file.size < Math.pow(1024, 2)) {
        success(file)
        return
      }

      const name = file.name // 文件名
      const reader = new FileReader()
      let Orientation = null

      reader.readAsDataURL(file)
      // debugger
      EXIF.getData(file, function () {
        Orientation = EXIF.getTag(this, 'Orientation')
      })

      reader.onload = e => {
        const src = e.target.result

        const img = new Image()
        img.src = src
        // eslint-disable-next-line no-unused-vars
        img.onload = e => {
          const w = img.width
          const h = img.height
          let imgWidth = w
          let imgHeight = h

          // 限制图片尺寸
          if (imgWidth > imgHeight && imgWidth > 750) {
            imgWidth = 750
            imgHeight = Math.ceil(750 * h / w)
          } else if (imgWidth < imgHeight && imgHeight > 1334) {
            imgWidth = Math.ceil(1334 * w / h)
            imgHeight = 1334
          }

          const quality = this.compressQuality
          // 生成canvas
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          canvas.width = imgWidth
          canvas.height = imgHeight

          // 铺底色 PNG转JPEG时透明区域会变黑色
          ctx.fillStyle = '#fff'
          ctx.fillRect(0, 0, imgWidth, imgHeight)
          // 解决ios拍照旋转问题
          if (Orientation && Orientation !== 1) {
            switch (Orientation) {
              case 6: // 旋转90度
                canvas.width = imgHeight
                canvas.height = imgWidth
                ctx.rotate(Math.PI / 2)
                // (0,-imgHeight) 从旋转原理图那里获得的起始点
                ctx.drawImage(img, 0, -imgHeight, imgWidth, imgHeight)
                break
              case 3: // 旋转180度
                ctx.rotate(Math.PI)
                ctx.drawImage(img, -imgWidth, -imgHeight, imgWidth, imgHeight)
                break
              case 8: // 旋转-90度
                canvas.width = imgHeight
                canvas.height = imgWidth
                ctx.rotate(3 * Math.PI / 2)
                ctx.drawImage(img, -imgWidth, 0, imgWidth, imgHeight)
                break
            }
          } else {
            ctx.drawImage(img, 0, 0, imgWidth, imgHeight)
          }
          // quality值越小，所绘制出的图像越模糊
          // toDataURL参数为PNG时不支持传图片质量，所以需要写死image/jpeg或image/webp，具体可以参考toDataURL的api
          const base64 = canvas.toDataURL('image/jpeg', quality) // 图片格式jpeg或webp可以选0-1质量区间

          // 去掉url的头，并转换为byte
          const bytes = window.atob(base64.split(',')[1])
          // 处理异常,将ascii码小于0的转换为大于0
          const ab = new ArrayBuffer(bytes.length)
          const ia = new Uint8Array(ab)
          for (let i = 0; i < bytes.length; i++) {
            ia[i] = bytes.charCodeAt(i)
          }
          file = new Blob([ab], { type: 'image/jpeg' })
          file.name = name

          success(file)
        }
        img.onerror = e => {
          this.$toast('压缩失败')
          console.log(e)
        }
      }
      reader.onerror = e => {
        this.$toast('压缩失败')
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .my-upload-wrapper{
    display: flex;
    flex-wrap: wrap;
  }
  .my-img{
    display: block;
    border-radius: 8px;
    width: 160px;
    height: 160px;
  }
  .my-img-wrapper{
    position: relative;
    margin-bottom: 30px;
    margin-right: 48px;
  }
  .close-btn{
    position: absolute;
    top: 0px;
    right: 0px;
    transform: translate(50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #B5B5B5;
    font-size: 34px;
    border-radius: 100%;
    &::after{
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      height: 80%;
      background: #fff;
      z-index: -1;
      border-radius: 50%;
    }
  }
  ::v-deep .van-uploader__upload{
    border: 1px dashed #C8D1D6;
    .van-uploader__upload-icon{
      color: #A8B8C0;
      font-weight: bold;
    }
  }
  .upload-mask{
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(0, 0, 0, .5);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .success{
    color: #07c160;
  }
</style>
<style lang="css">
  .my-upload-wrapper .upload-progress .van-circle__text{
    color: #fff;
  }
</style>
